import repository from './repository'

const resource = 'stock-default-details'

export default {
  // index () {
  //   return repository.get(resource)
  // },api
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  delete (id) {
    return repository.delete(`${resource}/${id}/delete`)
  },
}
