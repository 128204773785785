<template lang="pug">
  div
    v-overlay(
      absolute
      v-model="stockDefaultDetailPosting"
    )
      v-progress-circular(
        indeterminate
        size="30"
      )
    v-text-field(
      label="Price"
      type="number"
      prefix="₱"
      :error-messages="stockDefaultDetailPostErrors.price"
      v-model.number="price"
    )
    v-btn(
      :color="editMode ? 'yellow darken-4' : 'primary'"
      small
      dark
      @click="sendRequest"
    )
      span {{ editMode ? 'Update' : 'Save' }}
    v-btn.ml-2(
      v-if="editMode"
      color="error"
      small
      dark
      @click="deleteItem"
    )
      span delete
    v-btn.ml-2(
      v-if="editMode"
      color="primary"
      small
      dark
      @click="resetDialog"
    )
      span cancel
</template>
<script>
import stockDefaultDetailRepository from '@/repositories/stock-default-detail.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [stockDefaultDetailGetVars, stockDefaultDetailGetVarNames] = requestVars({ identifier: 'stock-default-detail', request: 'post', hasData: false })
const stockDefaultDetailGetHandler = new VueRequestHandler(null, stockDefaultDetailGetVarNames)

const inputVars = () => ({
  id: null,
  stockId: null,
  price: null,
})

export default {
  name: 'CreateStockDefaultDetail',
  props: {
    value: {
      type: Object,
      default: () => ({ }),
    },
  },
  data () {
    return {
      ...inputVars(),
      ...stockDefaultDetailGetVars,
    }
  },
  computed: {
    editMode () {
      return this.value.id
    },
  },
  watch: {
    value (item) {
      this.populateInputs(item)
    },
  },
  methods: {
    deleteItem () {
      this.$confirm('are you sure?').then(this.destroy)
    },
    destroy (confirm) {
      if (!confirm) return
      const handler = stockDefaultDetailGetHandler
      const repository = stockDefaultDetailRepository.delete
      const { id } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [id], this.resetDialog)
    },
    sendRequest () {
      if (this.editMode) {
        this.update()
        return
      }
      this.store()
    },
    store () {
      const handler = stockDefaultDetailGetHandler
      const repository = stockDefaultDetailRepository.store
      const { data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [data], this.resetDialog)
    },
    update () {
      const handler = stockDefaultDetailGetHandler
      const repository = stockDefaultDetailRepository.update
      const { id, data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [id, data], this.resetDialog)
    },
    resetDialog () {
      this.reset()
      this.$emit('input', {})
      this.$emit('close')
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    getInputData () {
      const excluded = ['id']
      const data = Object.keys(inputVars())
        .filter(key => !excluded.includes(key))
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
      const { id } = this
      return { id, data }
    },
    populateInputs (item) {
      Object.keys(item).forEach(key => {
        const key2 = key.snakeToCamelCase()
        if (this[key2] !== item[key]) this[key2] = item[key]
      })
    },
  },
}
</script>
